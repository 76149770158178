import { create } from 'zustand';
import axiosInstance from '../hooks/axiosInstance';
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import Cookie from 'js-cookie';
export const useAuthStore = create((set) => ({
    user: null,
    loading: true,
    initializing: true, // Controls initialization
    authStatus: "unauthenticated", // Track the user's auth state
    modalShow: true,

    login: (userData, rawData, queryClient) => {
        console.log("Login called with user data:", userData);
        localStorage.setItem('authToken', rawData);  // Store authToken
        set({ user: userData, authStatus: "authenticated", loading: false, initializing: false });
        queryClient.setQueryData(['user'], userData);
    },

    logout: (queryClient) => {
        console.log("Logout called");
        localStorage.removeItem('authToken');
        set({ user: null, authStatus: "unauthenticated", loading: false, initializing: false });
        queryClient.removeQueries(['user']);
    },
    setModalShow: (value) => {
        set({ modalShow: value });
    },

    registerUser: async (queryClient) => {
        console.log("RegisterUser called");
        try {
            let userInfo, rawData, startParam;
            // localStorage.removeItem('authToken');
            // localStorage.removeItem('initDataRaw');
            if (process.env.REACT_APP_ENV === 'local') {
                userInfo = {
                    id: '774942278',
                    firstName: 'Rasel',
                    photoUrl: 'https://via.placeholder.com/150',
                };
                rawData = 'query_id=AAFGrjAuAAAAAEauMC6CvQJ3&user=%7B%22id%22%3A774942278%2C%22first_name%22%3A%22Rasel%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ProRasel%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730379811&hash=bf362efda23857d46b7cf462786a06ded20a2ef513ac4dd671fbb0af6bdeed64';
            } else {
                const { initData, initDataRaw } = retrieveLaunchParams();
               
                localStorage.setItem('initDataRaw', initDataRaw);
                
                userInfo = initData.user;
                
                rawData = initDataRaw;
                startParam = initData?.startParam;

                if (!userInfo) {
                    throw new Error("Telegram init data not found.");
                }
            }
            console.log("User info:", userInfo);

            const registrationData = { ...userInfo, referralCode: startParam };
            const response = await axiosInstance.post("user/auth/tele-sign", registrationData);
            const { actionRequired, user,initialSponsor,token } = response.data;
            //  localStorage.setItem('authToken', rawData);

            if (actionRequired === "linkOrCreateAccount") {
                localStorage.setItem('sponsor',initialSponsor?.userId || '');
                console.log("Action required to link or create account");
                set({ authStatus: "incomplete", loading: false, initializing: false });
            } else {
                console.log("User registration/login complete, navigating to home");
                 localStorage.setItem('authToken', rawData);
                 Cookie.set('tmaToken', rawData);
                   const userWithToken = { ...user, token:token };
                set({ user:userWithToken, authStatus: "authenticated", loading: false, initializing: false });
               
                queryClient.setQueryData(['user'], user);
            }
        } catch (error) {
            console.error("Registration failed:", error);
            set({ user: null, authStatus: "unauthenticated", loading: false, initializing: false });
            queryClient.removeQueries(['user']);
        }
    },

    fetchUserData: async (queryClient) => {
        console.log("FetchUserData called");
        try {
            const { data } = await axiosInstance.get("user/profile");
            set({ user: data.user, loading: false, authStatus: "authenticated", initializing: false });
        } catch (error) {
            console.error("Failed to fetch user data:", error);
            set({ user: null, authStatus: "unauthenticated", loading: false, initializing: false });
            queryClient.removeQueries(['user']);
        }
    },
}));
