import React, { useState } from "react";
import { Layout } from "../components/Layout";
import coinIcon from "../assets/img/CatsCoin.png"; // Replace with an appropriate icon path if available
import HeaderArea from "../components/home/HeaderArea";
import { useAuthStore } from "../store/useAuthStore";

const Swap = () => {
    const [swapFrom, setSwapFrom] = useState("");
    const [swapTo, setSwapTo] = useState("");
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");
    const { user } = useAuthStore();

    const handleSwapSubmit = (e) => {
        e.preventDefault();
        if (!swapFrom || !swapTo || !amount) {
            setError("All fields are required");
            return;
        }
        setError("");
        // Logic to handle the swap action goes here
        console.log("Swapping:", { swapFrom, swapTo, amount });
    };

    return (
        <Layout active={2} hideheaderbg={true}>
            <HeaderArea user={user} />
            <div className="flex items-center justify-center py-10 px-4">
                <div className="w-full max-w-lg p-6 bg-main rounded-2xl shadow-md">
                    <h2 className="text-2xl font-bold text-white text-center mb-6">Swap Assets</h2>

                    <form onSubmit={handleSwapSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="swapFrom" className="block text-lg font-semibold text-[#9A9BA0] mb-1">Swap From</label>
                            <input
                                type="text"
                                id="swapFrom"
                                value={swapFrom}
                                onChange={(e) => setSwapFrom(e.target.value)}
                                placeholder="Asset to swap from"
                                className="w-full px-4 py-3 text-white bg-[#3A3A44] rounded-lg outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="swapTo" className="block text-lg font-semibold text-[#9A9BA0] mb-1">Swap To</label>
                            <input
                                type="text"
                                id="swapTo"
                                value={swapTo}
                                onChange={(e) => setSwapTo(e.target.value)}
                                placeholder="Asset to swap to"
                                className="w-full px-4 py-3 text-white bg-[#3A3A44] rounded-lg outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="amount" className="block text-lg font-semibold text-[#9A9BA0] mb-1">Amount</label>
                            <input
                                type="text"
                                id="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter amount"
                                className="w-full px-4 py-3 text-white bg-[#3A3A44] rounded-lg outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>

                        {error && <p className="text-red-500 text-sm">{error}</p>}

                        <button
                            type="submit"
                            className="w-full py-3 bg-secondary text-white font-bold rounded-lg transition duration-200 hover:shadow-lg"
                        >
                            Swap Now
                        </button>
                    </form>

                    {/* Example of showing a coin icon for added visual context */}
                    <div className="flex justify-center mt-6">
                        <img src={coinIcon} alt="Coin Icon" className="h-12 w-12" />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Swap;
