import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useTapInfo, useProcessTap } from '../api/tap';
import { debounce } from 'lodash';
import { useAuthStore } from '../store/useAuthStore';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {

  const { user } = useAuthStore();
  const [gameState, setGameState] = useState({
    coinBalance: 0,
    dailyCoinsEarned: 0,
    dailyLimit: 1000,
    coinsPerTap: 1,
    isLimitReached: false,
    error: null,
  });

  const coinBatchRef = useRef(0); // Tracks coins accumulated in the current batch
  const retryQueueRef = useRef(0); // Holds the amount to retry in case of failures
  const syncTimeoutRef = useRef(null); // Timeout reference for retries

  const { data: initialTapInfo, isLoading: isTapInfoLoading, isError } = useTapInfo(!!user);
  const { mutate: processTap } = useProcessTap();

  /**
   * Flush the batched coins to the backend.
   */
  const flushBatch = useCallback(async () => {
    const batchAmount = coinBatchRef.current + retryQueueRef.current; // Combine new batch + retry amount

    if (batchAmount === 0) return; // Skip if there's nothing to sync

    coinBatchRef.current = 0; // Reset the batch amount immediately
    retryQueueRef.current = 0; // Reset retry queue

    try {
      await processTap(batchAmount); // Send only the amount
      setGameState((prev) => ({
        ...prev,
        error: null, // Clear any previous errors
      }));
    } catch (error) {
      // If syncing fails, re-add the batch amount to retry queue
      retryQueueRef.current += batchAmount;
      setGameState((prev) => ({
        ...prev,
        error: 'Failed to sync coins. Retrying...',
      }));

      // Retry syncing after 3 seconds
      syncTimeoutRef.current = setTimeout(() => debouncedFlushBatch(), 3000);
    }
  }, [processTap]);

  /**
   * Debounced version of `flushBatch` to allow cancellation.
   */
  const debouncedFlushBatch = useCallback(debounce(flushBatch, 1000), [flushBatch]);

  /**
   * Handle taps and optimistically update the UI.
   */
  const handleTap = useCallback(() => {
    const { coinsPerTap, dailyCoinsEarned, dailyLimit, isLimitReached } = gameState;

    if (isLimitReached) {
      setGameState((prev) => ({
        ...prev,
        error: 'Daily tap limit reached. Please try again tomorrow.',
      }));
      return;
    }

    const remainingCoins = dailyLimit - dailyCoinsEarned;

    // Check if the current tap exceeds the daily limit
    if (remainingCoins <= coinsPerTap) {
      // Credit only the remaining coins
      setGameState((prev) => ({
        ...prev,
        dailyCoinsEarned: prev.dailyCoinsEarned + remainingCoins,
        coinBalance: initialTapInfo.todayReviewed > 0
          ? prev.coinBalance + remainingCoins // Update coinBalance only if todayReviewed > 0
          : prev.coinBalance,
        isLimitReached: true, // Mark limit as reached
        error: 'Daily tap limit reached. Please try again tomorrow.',
      }));

      // Add remaining coins to the batch for backend sync
      coinBatchRef.current += remainingCoins;
      return;
    }

    // Credit the full coinsPerTap if within the limit
    setGameState((prev) => ({
      ...prev,
      dailyCoinsEarned: prev.dailyCoinsEarned + coinsPerTap,
      coinBalance: initialTapInfo.todayReviewed > 0
        ? prev.coinBalance + coinsPerTap // Update coinBalance only if todayReviewed > 0
        : prev.coinBalance,
    }));

    // Add coinsPerTap to the batch for backend sync
    coinBatchRef.current += coinsPerTap;
  }, [gameState]);



  /**
   * Initialize the game state with data from the backend if the state is uninitialized.
   */
  useEffect(() => {
    if (initialTapInfo && !gameState.dailyCoinsEarned && !gameState.coinBalance) {
      setGameState((prev) => ({
        ...prev,
        coinBalance: initialTapInfo.currentCoinBalance ?? 0,
        dailyCoinsEarned: initialTapInfo.dailyCoinsEarned ?? 0,
        dailyLimit: initialTapInfo.dailyLimit ?? prev.dailyLimit,
        coinsPerTap: initialTapInfo.coinsPerTap ?? prev.coinsPerTap,
        isLimitReached:
          (initialTapInfo.dailyCoinsEarned ?? 0) >=
          (initialTapInfo.dailyLimit ?? prev.dailyLimit),
      }));
    }
  }, [initialTapInfo, gameState.dailyCoinsEarned, gameState.coinBalance]);


  /**
   * Automatically flush the batch every 2 seconds.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      debouncedFlushBatch();
    }, 2000);

    return () => {
      clearInterval(interval);
      debouncedFlushBatch.cancel(); // Cancel the debounced function
    };
  }, [debouncedFlushBatch]);

  /**
   * Cleanup on unmount.
   */
  useEffect(() => {
    return () => {
      clearTimeout(syncTimeoutRef.current);
      debouncedFlushBatch(); // Ensure any remaining batch is flushed
    };
  }, [debouncedFlushBatch]);

  return (
    <GameContext.Provider value={{ gameState, handleTap, isTapInfoLoading, isError }}>
      {children}
    </GameContext.Provider>
  );
};
