import { create } from 'zustand';
import { debounce } from 'lodash';

export const useGameStore = create((set, get) => {
  const pendingUpdatesQueue = [];
  const syncTimeoutRef = { current: null };

  const syncWithServer = debounce(async (processTap) => {
    const { syncInProgress } = get();

    if (pendingUpdatesQueue.length === 0 || syncInProgress) return;

    // Start syncing process
    set({ syncInProgress: true });
    const updatesToSync = [...pendingUpdatesQueue];
    pendingUpdatesQueue.length = 0;

    try {
      const totalCoinsToSync = updatesToSync.reduce((sum, update) => sum + update, 0);
      const response = await processTap.mutateAsync(totalCoinsToSync);

      if (response) {
        set((state) => ({
          coinBalance: response.currentCoinBalance ?? state.coinBalance,
          dailyCoinsEarned: response.dailyCoinsEarned ?? state.dailyCoinsEarned,
          isLimitReached: response.dailyLimitReached ?? state.isLimitReached,
          syncInProgress: false,
          error: null,
          pendingCoins: 0,
        }));
      }
    } catch (error) {
      // If sync fails, re-add the updates to the queue
      pendingUpdatesQueue.push(...updatesToSync);
      set({
        syncInProgress: false,
        error: 'Failed to sync coins. Retrying...',
      });

      // Retry syncing after 3 seconds
      syncTimeoutRef.current = setTimeout(() => syncWithServer(processTap), 3000);
    }
  }, 1000);

  return {
    coinBalance: 0,
    dailyCoinsEarned: 0,
    dailyLimit: 0,
    coinsPerTap: 0,
    isLimitReached: false,
    pendingCoins: 0,
    syncInProgress: false,
    error: null,

    initializeGameState: async (initialTapInfo) => {
      if (initialTapInfo) {
        set({
          coinBalance: initialTapInfo.currentCoinBalance,
          dailyCoinsEarned: initialTapInfo.dailyCoinsEarned,
          dailyLimit: initialTapInfo.dailyLimit,
          coinsPerTap: initialTapInfo.coinsPerTap,
          isLimitReached:
            initialTapInfo.dailyCoinsEarned >= initialTapInfo.dailyLimit,
        });
      }
    },

    handleTap: (processTap) => {
      const {
        coinsPerTap,
        dailyCoinsEarned,
        dailyLimit,
        isLimitReached,
      } = get();

      if (isLimitReached || dailyCoinsEarned + coinsPerTap > dailyLimit) {
        set({
          error: 'Daily tap limit reached. Please try again tomorrow.',
          isLimitReached: true,
        });
        return;
      }

      // Optimistic update
      set((state) => ({
        dailyCoinsEarned: state.dailyCoinsEarned + coinsPerTap,
        pendingCoins: state.pendingCoins + coinsPerTap,
      }));

      pendingUpdatesQueue.push(coinsPerTap);
      syncWithServer(processTap);
    },

    cleanup: () => {
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
      syncWithServer.cancel();
    },
  };
});
