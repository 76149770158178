// hooks/useTapGame.js
import { useState, useRef, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { useAuthStore } from '../store/useAuthStore';
import { useProcessTap, useTapInfo } from '../api/tap';

const INITIAL_GAME_STATE = {
  coinBalance: 0,
  pendingCoins: 0,
  dailyCoinsEarned: 0,
  dailyLimit: 1000,
  coinsPerTap: 1,
  isLimitReached: false,
  error: null,
  syncInProgress: false
};

export const useTapGame = () => {
  const [gameState, setGameState] = useState(INITIAL_GAME_STATE);
  const pendingUpdatesQueue = useRef([]);
  const syncTimeoutRef = useRef(null);
  const { user, loading } = useAuthStore();
  const { data: initialTapInfo, isLoading, isError } = useTapInfo();
  const { mutate: processTap } = useProcessTap();

  // Handle limit reached
  const handleLimitReached = useCallback(() => {
    setGameState(prev => ({
      ...prev,
      error: "Daily tap limit reached. Please try again tomorrow.",
      isLimitReached: true
    }));
  }, []);

  // Handle sync error
  const handleSyncError = useCallback(() => {
    setGameState(prev => ({
      ...prev,
      syncInProgress: false,
      error: "Failed to sync coins. Retrying...",
    }));

    syncTimeoutRef.current = setTimeout(() => {
      syncWithServer();
    }, 3000);
  }, []);

  // Optimized server sync with backoff retry
  const syncWithServer = useCallback(
    debounce(async () => {
      if (pendingUpdatesQueue.current.length === 0 || gameState.syncInProgress) return;

      setGameState(prev => ({ ...prev, syncInProgress: true }));
      const updatesToSync = [...pendingUpdatesQueue.current];
      pendingUpdatesQueue.current = [];

      try {
        const totalCoinsToSync = updatesToSync.reduce((sum, update) => sum + update, 0);
        const response = await processTap(totalCoinsToSync);

        if (response) {
          setGameState(prev => ({
            ...prev,
            coinBalance: response.currentCoinBalance ?? prev.coinBalance,
            dailyCoinsEarned: response.dailyCoinsEarned ?? prev.dailyCoinsEarned,
            isLimitReached: response.dailyLimitReached ?? prev.isLimitReached,
            syncInProgress: false,
            error: null,
            pendingCoins: 0
          }));
        }
      } catch (error) {
        pendingUpdatesQueue.current = [...updatesToSync];
        handleSyncError();
      }
    }, 1000),
    [handleSyncError]
  );

  const handleTap = useCallback((event) => {
    const { coinsPerTap, dailyCoinsEarned, dailyLimit, isLimitReached } = gameState;

    if (isLimitReached || dailyCoinsEarned + coinsPerTap > dailyLimit) {
      handleLimitReached();
      return;
    }

    // Optimistic update
    setGameState(prev => ({
      ...prev,
      coinBalance: prev.coinBalance + coinsPerTap,
      dailyCoinsEarned: prev.dailyCoinsEarned + coinsPerTap,
      pendingCoins: prev.pendingCoins + coinsPerTap
    }));

    pendingUpdatesQueue.current.push(coinsPerTap);
    syncWithServer();
  }, [gameState, syncWithServer, handleLimitReached]);

  // Initialize game state
  useEffect(() => {
    if (initialTapInfo) {
      setGameState(prev => ({
        ...prev,
        coinBalance: initialTapInfo?.currentCoinBalance ?? 0,
        dailyCoinsEarned: initialTapInfo?.dailyCoinsEarned ?? 0,
        dailyLimit: initialTapInfo?.dailyLimit ?? prev.dailyLimit,
        coinsPerTap: initialTapInfo?.coinsPerTap ?? prev.coinsPerTap,
        isLimitReached: (initialTapInfo?.dailyCoinsEarned ?? 0) >= (initialTapInfo?.dailyLimit ?? prev.dailyLimit)
      }));
    }
  }, [initialTapInfo]);

  // Cleanup
  useEffect(() => {
    return () => {
      clearTimeout(syncTimeoutRef.current);
      syncWithServer.cancel();
    };
  }, [syncWithServer]);

  return {
    gameState,
    handleTap,
    isLoading: isLoading || loading,
    isError,
    user
  };
};