import React, { createContext, useContext, useState } from "react";

// Context for notifications
const NotificationContext = createContext();

// Provider for Notification Context
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    isOpen: false,
    type: "info", // success, warning, error, etc.
    message: "",
  });

  const showNotification = (type, message) => {
    setNotification({ isOpen: true, type, message });
    // Auto-close the notification after 3 seconds
    setTimeout(() => setNotification({ isOpen: false, type: "info", message: "" }), 3000);
  };

  const closeNotification = () => {
    setNotification({ ...notification, isOpen: false });
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification, closeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};
