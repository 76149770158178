import React from 'react';

const Input = ({ className = '', ...props }) => {
    return (
        <input
            className={`w-full px-3 py-2 text-[#FFFFFF] bg-[#000] rounded-md border border-[#2C2A33] focus:outline-none focus:ring-1 focus:ring-[#eee] ${className}`}
            {...props}
        />
    );
};

export default Input;
