import { X } from "lucide-react"; // Import the close (X) icon

const NoticeModal = ({ isOpen, onClose, title, message, loading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div
        className="bg-main border-main shadow-glow rounded-xl p-6 w-full max-w-md border border-[#DAA520]/10 relative overflow-y-auto"
        style={{
          maxHeight: "90vh", // Set maximum height to 90% of the viewport
        }}
      >
        {/* Close icon in the top-right corner */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-gray-300 focus:outline-none"
        >
          <X className="w-5 h-5" /> {/* Render the Lucide close icon */}
        </button>

        {/* Modal content */}
        <h3 className="text-xl font-bold text-white mb-4 text-center">
          {title}
        </h3>
        <p
          className="text-gray-300 mb-6 "
          dangerouslySetInnerHTML={{ __html: message }} // Render HTML content
        />
        <div className="flex justify-center gap-3 mt-8">
          <button
            onClick={onClose}
            className="bg-main border border-main px-4 text-white/90 py-1.5 rounded-full cursor-pointer transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoticeModal;
