import React, { memo, useRef, useImperativeHandle, forwardRef } from 'react';

export const TapAnimation = memo(forwardRef(({ waves }, ref) => {
  const numberAnimationRef = useRef(null);

  useImperativeHandle(ref, () => ({
    showNumberAnimation: (x, y, amount) => {
          console.log(`showNumberAnimation called with: x=${x}, y=${y}, amount=${amount}`);

      if (!numberAnimationRef.current) return;

      const animationDuration = 700;
      const newItem = document.createElement('li');
      newItem.style.left = `${x}px`;
      newItem.style.top = `${y}px`;
      newItem.style.transitionDuration = `${animationDuration}ms`;
      newItem.className = 'fixed text-[#fff] font-bold text-[50px] z-50 pointer-events-none translate-x-[-40%]';
      newItem.innerText = `+${amount}`;

      numberAnimationRef.current.appendChild(newItem);

      requestAnimationFrame(() => {
        newItem.classList.add('animate_one');
        newItem.style.top = `${y - 100}px`;
      });

      setTimeout(() => {
        if (numberAnimationRef.current?.contains(newItem)) {
          newItem.remove();
        }
      }, animationDuration);
    }
  }));

  return (
    <>
      {/* Number animations container */}
      <ul ref={numberAnimationRef} className="absolute inset-0 overflow-hidden pointer-events-none" />
      
      {/* Wave animations */}
      {/* {waves.map((wave) => (
        <div
          key={wave.id}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-yellow-300/50 animate-wave pointer-events-none"
        />
      ))} */}
    </>
  );
}));

TapAnimation.displayName = 'TapAnimation';

// Required CSS in your styles file
const styles = `
@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.animate-wave {
  animation: wave 0.6s ease-out forwards;
  width: 100px;
  height: 100px;
}

.animate_one {
  opacity: 0;
  transform: scale(0.5) translateY(-20px);
  transition: all 0.5s ease-out;
}
`;