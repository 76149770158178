import axiosInstance from "../hooks/axiosInstance";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// API Call to get tap information
const getUserTapInfo = async () => {
  const response = await axiosInstance.get(`/user/bot/info`);
  return response.data.data; 
};

// API Call to process taps
const processTapRequest = async (coinsToAdd) => {
  const response = await axiosInstance.post(`/user/bot/tap`, { coinsToAdd });
 return Promise.resolve(response.data.data); 
};

// React Query Hook to get tap info (GET request)
export const useTapInfo = (enabled = true) => {
  return useQuery({
    queryKey: ["tapInfo"],
    queryFn: getUserTapInfo,
    enabled, // Only fetch if this is true
    refetchOnWindowFocus: true, 
    refetchInterval: 180000, 
    onSuccess: (data) => {
      console.log("Tap info fetched successfully:", data);
    },
    onError: (error) => {
      console.error("Error fetching tap info:", error);
    },
  });
};


// React Query Hook to process taps in bulk (POST request)
export const useProcessTap = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processTapRequest,
    onSuccess: (data) => {
      console.log("Tap processed successfully:", data);

      // Update the 'tapInfo' cache with the latest balance and limits
      queryClient.setQueryData(["tapInfo"], (oldData) => ({
        ...oldData,
        currentCoinBalance: data.currentCoinBalance,
        dailyCoinsEarned: data.dailyCoinsEarned,
        remainingTapAllowance: data.remainingTapAllowance,
        coinsPerTap: data.coinsPerTap || oldData.coinsPerTap,
        dailyLimit: data.dailyLimit || oldData.dailyLimit,
      }));
    },
    onError: (error) => {
      console.error("Error processing tap:", error);
    },
  });
};
