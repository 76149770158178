
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../hooks/axiosInstance";

// API call to fetch the latest offer notice
const fetchLatestOfferNotice = async () => {
  const response = await axiosInstance.get(`/user/dashboard/offer-notices`, {
    params: { limit: 1 }, // Fetch only the latest notice
  });
  return response.data.offerNotice[0]; // Assumes the backend sends { offerNotice, page, hasMore }
};

export const useLatestOfferNotice = () => {
  return useQuery({
    queryKey: ["latestOfferNotice"],
    queryFn: fetchLatestOfferNotice,
    staleTime: 1000 * 60 * 5, // Cache the latest notice for 5 minutes
    onSuccess: (data) => {
      console.log("Latest offer notice fetched successfully:", data);
    },
    onError: (error) => {
      console.error("Error fetching the latest offer notice:", error);
    },
  });
};