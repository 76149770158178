import axiosInstance from "../hooks/axiosInstance";
import { useMutation } from "@tanstack/react-query";

// API Call for updating credentials
const updateCredentials = async (credentials) => {
  const response = await axiosInstance.post(`/user/profile/change-password-pin`, credentials);
  return response.data;
};

// React Query Hook for updating credentials
export const useUpdateCredentials = () => {
  return useMutation({
    mutationFn: updateCredentials,
    onSuccess: (data) => {
      console.log("Credentials updated successfully:", data);
    },
    onError: (error) => {
      console.error("Error updating credentials:", error);
    },
  });
};

const updateReferralCode = async (referralCode) => {
  const response = await axiosInstance.post(`/user/profile/update-referralcode`, { referralCode });
  return response.data;
};
export const useUpdateReferralCode = () => {
  return useMutation({
    mutationFn: updateReferralCode,
    onSuccess: (data) => {
      console.log("Referral code updated successfully:", data);
    },
    onError: (error) => {
      console.error("Error updating referral code:", error);
    },
  });
};