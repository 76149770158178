import { useState, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

const RewardModal = ({ isOpen, onClose, content, actionText, onConfirm, loading }) => {
    const [transactionPin, setTransactionPin] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Debounced Submit Handler
    const debouncedOnConfirm = useMemo(
        () =>
            debounce(async (transactionPin) => {
                if (!transactionPin) {
                    setError("Transaction PIN is required");
                    return;
                }

                setIsSubmitting(true); // Set submitting to true
                try {
                    await onConfirm(transactionPin); // Call the confirm action
                } catch (error) {
                    setError(error.message || "Action failed"); // Display error message
                } finally {
                    setIsSubmitting(false); // Reset submitting state on success or error
                }
            }, 300),
        [onConfirm]
    );

    const handleSubmit = () => {
        if (loading) return; // Prevent submission if already loading
        debouncedOnConfirm(transactionPin);
    };

    const handlePinChange = (e) => {
        setTransactionPin(e.target.value);
        if (error) setError(''); // Clear error when PIN changes
    };

    // Cleanup the debounce function on component unmount
    useEffect(() => {
        return () => debouncedOnConfirm.cancel();
    }, [debouncedOnConfirm]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className=" bg-main border-main shadow-glow rounded-xl p-6 w-full max-w-md border border-[#DAA520]/10">
                <h3 className="text-xl font-bold text-white mb-4">{actionText}</h3>

                <div
                    className="text-gray-300 mb-6"
                    dangerouslySetInnerHTML={{ __html: content }}
                />

                <div className="relative mb-6">
                    <input
                        id="transactionPinInput"
                        type="password"
                        placeholder="Enter Transaction PIN"
                        value={transactionPin}
                        onChange={handlePinChange}
                        disabled={loading || isSubmitting}
                        className={`w-full p-3 bg-main text-white rounded-lg border ${
                            error ? 'border-red-500' : 'border-main'
                        } focus:outline-none focus:border-main transition-colors`}
                    />
                    {error && (
                        <p className="absolute -bottom-6 left-0 text-red-500 text-sm">{error}</p>
                    )}
                </div>

                <div className="flex justify-end gap-3 mt-8">
                    <button
                        onClick={onClose}
                        disabled={loading || isSubmitting}
                        className="bg-main  border border-main px-4 text-white/90 py-1.5 rounded-full cursor-pointer  transition-colors"
                    >
                        Close
                    </button>

                    <button
                        onClick={handleSubmit}
                        disabled={loading || isSubmitting || !transactionPin}
                        className={` bg-main  border flex items-center justify-center border-main px-3 py-1.5 rounded-full text-white/90 cursor-pointer min-w-[100px] ${
                            loading || isSubmitting
                                ? ' cursor-not-allowed'
                                : ' '
                        } transition-colors`}
                    >
                        {loading || isSubmitting ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white/90"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Processing
                            </>
                        ) : (
                            actionText
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RewardModal;
