import React, { useEffect } from 'react'
import { Header } from './common/Header'
import { Menu } from './common/Menu'
export const Layout = ({ children, active, hideheaderbg, nomenu = null ,scroll = true }) => {
     useEffect(() => {
    if (!scroll) {
      // Scroll to top before disabling scrolling
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Disable scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      // Enable scrolling
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    }

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    };
  }, [scroll]);
  return (
    <div className=' background-gradien '>
      <div className={`w-[430px] min-h-screen  no-scroll background-gradient overflow-auto pb-[150px] mx-auto  relative ${nomenu && 'pb-[50px]'} xs:w-full `}>
        {/* <Header hideheaderbg={hideheaderbg} /> */}
        <div className='px-[8px]'>
          {children}
        </div>

        {
          nomenu == null && (
            <Menu active={active} />
          )
        }

      </div>
    </div>
  )
}
