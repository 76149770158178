import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import HeaderArea from "../components/home/HeaderArea";
import { useGame } from "../context/GamContext";
import { useAuthStore } from "../store/useAuthStore";
import { useUpdateCredentials } from "../api/credential";
import { useNotification } from "../hooks/useNotification"; // Assuming you have this hook

const CredentialUpdatePage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [transactionPin, setTransactionPin] = useState("");
  const [confirmTransactionPin, setConfirmTransactionPin] = useState("");
  const [error, setError] = useState("");
  const { gameState } = useGame();
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const { mutate: updateCredentials, status } = useUpdateCredentials();

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!password && !confirmPassword && !transactionPin && !confirmTransactionPin){
      setError("Please fill information");
      return;

    }
    console.log("password", password);
    console.log("confirmPassword,", confirmPassword);

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    if (transactionPin !== confirmTransactionPin) {
      setError("Transaction PINs do not match!");
      return;
    }

    setError(""); // Clear errors

    // Call the API to update credentials
    updateCredentials(
      { password,confirmPassword, transactionPin,confirmTransactionPin },
      {
        onSuccess: (response) => {
          showNotification("success", "Credentials updated successfully!");
          navigate("/"); // Redirect to home after success
        },
        onError: (error) => {
          showNotification("error", error.response?.data?.message || "Failed to update credentials.");
        },
      }
    );
  };

  return (
    <Layout active={1} hideheaderbg={true}>
      <HeaderArea user={user} gameState={gameState} />
      <div className="flex items-center justify-center mt-2 bg-black/50 backdrop-blur-sm">
        <div className="bg-main border-main shadow-glow rounded-xl p-6 w-full max-w-md border border-[#DAA520]/10">
          <h2 className="text-md font-bold text-white mb-4 text-center">
            Update Password & Transaction PIN
          </h2>
          {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm text-white font-medium mb-1">
                New Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-2 py-1 bg-main text-white rounded-lg border border-main focus:outline-none focus:border-[#DAA520] transition-colors"
                
              />
            </div>
            <div>
              <label className="block text-sm text-white font-medium mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-2 py-1 bg-main text-white rounded-lg border border-main focus:outline-none focus:border-[#DAA520] transition-colors"
                
              />
            </div>
            <div>
              <label className="block text-sm text-white font-medium mb-1">
                New Transaction PIN
              </label>
              <input
                type="password"
                value={transactionPin}
                onChange={(e) => setTransactionPin(e.target.value)}
                className="w-full px-2 py-1 bg-main text-white rounded-lg border border-main focus:outline-none focus:border-[#DAA520] transition-colors"
                
              />
            </div>
            <div>
              <label className="block text-sm text-white font-medium mb-1">
                Confirm Transaction PIN
              </label>
              <input
                type="password"
                value={confirmTransactionPin}
                onChange={(e) => setConfirmTransactionPin(e.target.value)}
                className="w-full px-2 py-1 bg-main text-white rounded-lg border border-main focus:outline-none focus:border-[#DAA520] transition-colors"
                
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-secondary text-white py-2 px-2 rounded-full mt-6 font-bold hover:bg-[#c3951e] transition-colors ${
                status==="pending" ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={status==="pending"}
            >
              {status==="pending" ? "Updating..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CredentialUpdatePage;
