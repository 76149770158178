import { Layout } from "../components/Layout";
import HeaderArea from "../components/home/HeaderArea";
import gift from "../assets/img/invite/gift.svg";
import coin from "../assets/img/CatsCoin.png";

import { useEffect, useState, useRef, useCallback } from "react";
import { useReferredUsers } from "../api/referrals";
import { useAuthStore } from "../store/useAuthStore";
import { Check, X } from "lucide-react";

export const Referral = () => {
  const { user } = useAuthStore();
  const botUrl = process.env.REACT_APP_TELEGRAM_BOT_URL;
  const [page, setPage] = useState(1); // Current page for pagination
  const [referredUsers, setReferredUsers] = useState([]);
  const { data, isLoading, isFetching, error } = useReferredUsers(user?._id, page, 10);

  // Append new data when fetched
  useEffect(() => {
    if (data?.selfSponsorUsers) {
      setReferredUsers((prevUsers) => [...prevUsers, ...data.selfSponsorUsers]);
    }
  }, [data]);

  // Handle infinite scroll
  const observer = useRef();
  const lastUserRef = useCallback((node) => {
    if (isFetching) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && data?.selfSponsorUsers?.length > 0 && page < data.totalPages) {
        setPage((prevPage) => prevPage + 1); // Fetch next page
      }
    });
    if (node) observer.current.observe(node);
  }, [isFetching, data, page]);

  function copyURL() {
    const userId = user.userId;
    const inviteLink = `${botUrl}?start=${userId}`;
    navigator.clipboard.writeText(inviteLink)
      .then(() => {
        window.alert("Copied Invitation Link");
      })
      .catch(err => {
        console.error("Failed to copy the link:", err);
      });
  }

  function shareToTelegram() {
    const userId = user._id;
    const inviteLink = `${botUrl}?start=${userId}`;
    const message = `Join me on this amazing app and earn rewards! Use my invite link: ${inviteLink}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(message)}`;
    window.open(telegramUrl, '_blank');
  }

  return (
    <Layout active={3} hideheaderbg={true}>
      <HeaderArea user={user} />

      {/* Invite Section */}
      <div className="flex flex-col gap-[12px] my-[24px]">
        <div onClick={shareToTelegram} className="border-[1px] flex items-center border-main bg-main p-2 rounded-[12px] gap-2">
          <img src={gift} alt="" className="h-8" />
          <div>
            <h1 className="text-[16px] font-semibold text-[#FFFFFF]">Invite a friend</h1>
            <div className="flex items-center gap-[2px]">
            
              <p className="text-[14px] text-[#9A9BA0] font-normal">
                <span className="font-semibold text-white/90">Invite and grow your network</span>
              </p>
            </div>
          </div>
        </div>

      </div>

      {/* Referred Users List */}
      <div style={{

      }}
        className="" >
        <h1 className="flex items-center gap-1 text-[16px] font-bold text-white mb-4">
          List of your Referrals
        </h1>

        <ul className="space-y-3">
          {referredUsers.map((friend, index) => (
            <li
              key={friend._id}
              ref={index === referredUsers.length - 1 ? lastUserRef : null}
              className="flex items-center justify-between px-2 py-2 bg-main border border-main rounded-[12px] shadow-low backdrop-blur-sm"
            >
              <div className="flex items-center gap-3">
                <img
                  src={friend.photoUrl || coin}
                  alt={friend.username}
                  className="w-[20px] h-[20px] rounded-full border-2 border-main"
                />
                <div className="flex flex-col">
                  <span className="text-white font-medium text-sm">{friend.name}</span>
                  <span className="text-gray-400 text-sm">ID: {friend.userId}</span>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                {/* Today Reviewed */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400 mr-2">Today Reviewed</span>
                  {friend.todayReviewed ? (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-green-500 bg-opacity-20">
                      <Check size={16} className="text-green-500" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-red-500 bg-opacity-20">
                      <X size={16} className="text-red-500" />
                    </div>
                  )}
                </div>

                {/* Yesterday Reviewed */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400 mr-2">Yesterday Reviewed</span>
                  {friend.yesterdayReviewed ? (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-green-500 bg-opacity-20">
                      <Check size={16} className="text-green-500" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-red-500 bg-opacity-20">
                      <X size={16} className="text-red-500" />
                    </div>
                  )}
                </div>
              </div>

            </li>
          ))}

          {referredUsers.length === 0 && (
            <li className="text-center py-8 text-[#9A9BA0]">
              No referrals yet
            </li>
          )}



          {error && (
            <li className="text-center py-4 text-[#FF6B6B]">
              Failed to load friends
            </li>
          )}
        </ul>
      </div>
    </Layout>
  );
};
