import React, { useEffect } from "react";
import { AlertCircle, CheckCircle2, XCircle, AlertTriangle, X } from "lucide-react";

const NotificationModal = ({ isOpen, type, message, onClose, duration = 5000 }) => {
  useEffect(() => {
    if (isOpen && duration) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, duration, onClose]);

  if (!isOpen) return null;

  const getTypeConfig = (type) => {
    switch (type) {
      case "success":
        return {
          bgGradient: "bg-main",
          borderColor: "border-main",
          icon: <CheckCircle2 className="w-5 h-5 text-[#fbd002]" />,
          iconBg: "bg-[#fbd002]/10"
        };
      case "error":
        return {
          bgGradient: "bg-main",
          borderColor: "border-main",
          icon: <XCircle className="w-5 h-5 text-red-500" />,
          iconBg: "bg-red-500/10"
        };
      case "warning":
        return {
          bgGradient: "bg-main",
          borderColor: "border-main",
          icon: <AlertTriangle className="w-5 h-5 text-[#DAA520]" />,
          iconBg: "bg-[#DAA520]/10"
        };
      default:
        return {
          bgGradient: "bg-main",
          borderColor: "border-main",
          icon: <AlertCircle className="w-5 h-5 text-[#8B25F5]" />,
          iconBg: "bg-[#8B25F5]/10"
        };
    }
  };

  const config = getTypeConfig(type);

  return (
    <div className="fixed top-4 right-4 z-50 animate-slide-in-right">
      <div
        className={`
          ${config.bgGradient}
          border ${config.borderColor}
          p-4 rounded-xl shadow-lg
          flex items-center gap-3
          min-w-[320px] max-w-md
          backdrop-blur-md
          animate-fade-in
        `}
      >
        {/* Icon Circle */}
        <div className={`${config.iconBg} p-2 rounded-full`}>
          {config.icon}
        </div>

        {/* Message */}
        <span className="flex-1 text-white text-sm font-medium">
          {message}
        </span>

        {/* Close Button */}
        <button
          onClick={onClose}
          className={`
            w-6 h-6 flex items-center justify-center
            rounded-full hover:bg-white/10
            transition-colors duration-200
            focus:outline-none focus:ring-2 focus:ring-white/20
          `}
        >
          <X className="w-4 h-4 text-white/70" />
        </button>

        {/* Progress Bar */}
        <div className="absolute bottom-0 left-0 h-0.5 bg-white/10 w-full rounded-b-xl">
          <div 
            className={`h-full ${config.borderColor} rounded-b-xl transition-all duration-${duration}`}
            style={{
              width: '100%',
              animation: `shrink ${duration}ms linear forwards`
            }}
          />
        </div>
      </div>
    </div>
  );
};

// Add these animations to your global CSS or Tailwind config
const styles = `
  @keyframes slide-in-right {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes shrink {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }

  .animate-slide-in-right {
    animation: slide-in-right 0.3s ease-out;
  }

  .animate-fade-in {
    animation: fade-in 0.2s ease-out;
  }
`;

export default NotificationModal;