import axios from 'axios';
import { useAuthStore } from '../store/useAuthStore';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request Interceptor to inject token dynamically
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  console.log('token', token);
  if (token) {
    config.headers.Authorization = `tma ${token}`; 
  }
  return config;
});

// Response Interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through if the response is successful
  async (error) => {
  //  const authStore = useAuthStore.getState(); // Access the zustand store state directly

    if (error.response && error.response.status === 401) {
      console.log("Invalid token detected, clearing local storage and resetting auth.");

      // Clear token and auth state
      // localStorage.removeItem('authToken');
      // authStore.logout(); // Reset auth state

      // // Optionally reload the page or redirect to a login route
      // window.location.reload(); // Reload to trigger AuthWrapper and restart auth flow
      // OR
      // navigate('/login'); // Use if you're using a route-based redirection
    }

    return Promise.reject(error); // Return the error to handle it in other parts of the app if needed
  }
);

export default axiosInstance;
