import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import axiosInstance from '../hooks/axiosInstance';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../store/useAuthStore';
import { useQueryClient } from '@tanstack/react-query';
import { parseInitData } from '@telegram-apps/sdk';

const CreateAccount = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { login } = useAuthStore();
    const [formData, setFormData] = useState({
        password: '',
        transactionPassword: '',
        referralCode: localStorage.getItem('sponsor') || ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sponsor = localStorage.getItem('sponsor') || '';
    const referralCode = sponsor;
    console.log('sponsor', referralCode);

    // Password validation
    const validatePassword = (password) => {
       
        return '';
    };

    // Transaction password validation
    const validateTransactionPassword = (transactionPassword) => {
        if (transactionPassword.length <4) {
            return 'Transaction password must be minimum 4 digits';
        }
        if (!/^\d+$/.test(transactionPassword)) {
            return 'Transaction password must contain only numbers';
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear error when user starts typing
        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        
        // Validate password
        const passwordError = validatePassword(formData.password);
        if (passwordError) {
            newErrors.password = passwordError;
        }

        // Validate transaction password
        const transactionPasswordError = validateTransactionPassword(formData.transactionPassword);
        if (transactionPasswordError) {
            newErrors.transactionPassword = transactionPasswordError;
        }

        // Validate referral code
        if (!formData.referralCode) {
            newErrors.referralCode = 'Referral code is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        
        try {
            const rawData = process.env.REACT_APP_ENV === 'local'
                ? 'query_id=AAFGrjAuAAAAAEauMC6CvQJ3&user=%7B%22id%22%3A774942278%2C%22first_name%22%3A%22Rasel%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ProRasel%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730379811&hash=bf362efda23857d46b7cf462786a06ded20a2ef513ac4dd671fbb0af6bdeed64'
                : localStorage.getItem('initDataRaw');

            const initData = parseInitData(rawData);
            const { id, first_name, last_name, photo_url } = initData.user;

            console.log('initData', initData);

            const response = await axiosInstance.post('/user/auth/tele-sign', {
                id,
                firstName: first_name,
                lastName: last_name,
                photoUrl: photo_url,
                ...formData
            });

            if (response.data.token) {
                const userWithToken = { ...response.data.user, token: response.data.token };
                login(userWithToken, rawData, queryClient);
                navigate("/home");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || "Failed to create account. Please try again.";
            setErrors(prev => ({
                ...prev,
                submit: errorMessage
            }));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Layout hideheaderbg={true}>
            <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6">
                <h1 className="text-[18px] text-white/90 font-bold text-center mb-4">
                    Create a New Account
                </h1>
                <p className="text-[14px] font-normal text-[#9A9BA0] text-center mb-6">
                    Set up your account with a password, transaction password, and referral code.
                </p>

                <form onSubmit={handleSubmit} className="w-full max-w-md bg-main border border-main p-6 rounded-[12px] shadow-glow">
                    <label className="text-[#9A9BA0] text-[14px] font-normal mb-2">Password</label>
                    <Input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Min 6 characters"
                        required
                        className="mb-1"
                        error={errors.password}
                    />
                    {errors.password && (
                        <p className="text-[#FF6B6B] text-sm mb-4">{errors.password}</p>
                    )}
                    <label className="text-[#9A9BA0] text-[14px] font-normal mb-2">Transaction Password</label>
                    <Input
                        type="password"
                        name="transactionPassword"
                        value={formData.transactionPassword}
                        onChange={handleChange}
                        placeholder=" 4 digits"
                        required
                        className="mb-1"
                        maxLength={6}
                        error={errors.transactionPassword}
                    />
                    {errors.transactionPassword && (
                        <p className="text-[#FF6B6B] text-sm mb-4">{errors.transactionPassword}</p>
                    )}
                    <label className="text-[#9A9BA0] text-[14px] font-normal mb-2">Referral Code</label>

                    <Input
                        name="referralCode"
                        value={formData.referralCode}
                        onChange={handleChange}
                        placeholder="Referral Code"
                        required
                        type="text"
                       
                        className="mb-1"
                        error={errors.referralCode}
                    />
                    {errors.referralCode && (
                        <p className="text-[#FF6B6B] text-sm mb-4">{errors.referralCode}</p>
                    )}

                    {errors.submit && (
                        <p className="text-[#FF6B6B] text-sm mb-4">{errors.submit}</p>
                    )}

                    <Button 
                        type="submit" 
                        disabled={isSubmitting}
                        className={`w-full  bg-main border border-main text-white font-bold rounded-full mt-4 ${
                            isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
                        }`}
                    >
                        {isSubmitting ? 'Creating Account...' : 'Create Account'}
                    </Button>
                </form>

                <p className="text-[#9A9BA0] mt-4">
                    Already have an account?
                    <Link to="/link-account" className="text-[#8B25F5] font-bold ml-1">
                        Link Account
                    </Link>
                </p>
            </div>
        </Layout>
    );
};

export default CreateAccount;