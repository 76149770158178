import axiosInstance from "../hooks/axiosInstance";
import { useQuery } from "@tanstack/react-query";

const URL = process.env.REACT_APP_API_URL;

// Fetch referred users with pagination
const fetchReferredUsers = async ({ page, limit }) => {
  const response = await axiosInstance.get(`${URL}/user/team-info/self-sponsor-users-telegram-ads-reviewed?page=${page}&limit=${limit}`);
  return response.data;
};

export const useReferredUsers = (userId, page, limit) => {
  return useQuery({
    queryKey: ["referredUsers", page],
    queryFn: () => fetchReferredUsers({ page, limit }),
    enabled: !!userId, // Only run query if userId is defined
    keepPreviousData: true, // Keep previous data to avoid flickering
  });
};
