import React from 'react';
import { Layout } from './components/Layout';
import { TapArea } from './components/tap/TapArea';
import { CoinBalance } from './components/tap/CoinBalance';
import { useTapGame } from './hooks/useTapGame';
import { Loading } from './pages/Loading';
import HeaderArea from './components/home/HeaderArea';
import { ErrorDisplay } from './components/tap/ErrorDisplay';
import { DailyLimitIndicator } from './components/tap/DailyLimitIndicator';

export const WebApp = () => {
  const {
  
    user
  } = useTapGame();

  return (
    <Layout active={1} hideheaderbg={true}>
      <HeaderArea user={user} />
     

      {/* Embed iframe below tap area */}
      <div className="iframe-container my-4">
        <h3 className="text-center text-white text-lg mb-2">Earn More Coins</h3>
        <iframe
          src="https://cview-frontend.vercel.app/" // Replace with your subdomain URL
          title="Earn More"
          width="100%"
      
        
          allowFullScreen
          className="rounded-lg border h-screen border-[#DAA520]/20"
        ></iframe>
      </div>
    </Layout>
  );
};
