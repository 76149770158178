import React from 'react';
import { Send } from 'lucide-react';
import { FaWhatsapp, FaFacebookF, FaTwitter, FaLinkedinIn, FaFacebookMessenger } from 'react-icons/fa';
import { Layout } from '../components/Layout';
import HeaderArea from '../components/home/HeaderArea';
import { useAuthStore } from '../store/useAuthStore';
import gift from '../assets/img/invite/gift.svg';
import coin from '../assets/img/CatsCoin.png';

export const SocialShare = () => {
  const { user } = useAuthStore();
  const botUrl = process.env.REACT_APP_TELEGRAM_BOT_URL;
  const inviteLink = `${botUrl}?start=${user?.userId}`;
  const message = `Join me on this amazing app and earn rewards! Use my invite link: ${inviteLink}`;

  const socialPlatforms = [
    {
      name: 'Facebook',
      icon: <FaFacebookF size={20} />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inviteLink)}`,
      iconColor: '#1877F2', // Facebook blue
      bgColor: 'rgba(24, 119, 242, 0.2)'
    },
    {
      name: 'Twitter',
      icon: <FaTwitter size={20} />,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`,
      iconColor: '#1DA1F2', // Twitter blue
      bgColor: 'rgba(29, 161, 242, 0.2)'
    },
    {
      name: 'LinkedIn',
      icon: <FaLinkedinIn size={20} />,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(inviteLink)}&summary=${encodeURIComponent(message)}`,
      iconColor: '#0A66C2', // LinkedIn blue
      bgColor: 'rgba(10, 102, 194, 0.2)'
    },
    {
      name: 'Telegram',
      icon: <Send size={20} />,
      url: `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(message)}`,
      iconColor: '#0088cc', // Telegram blue
      bgColor: 'rgba(0, 136, 204, 0.2)'
    },
    {
      name: 'WhatsApp',
      icon: <FaWhatsapp size={20} />,
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
      iconColor: '#25D366', // WhatsApp green
      bgColor: 'rgba(37, 211, 102, 0.2)'
    }
    //  {
    //   name: 'Messenger',
    //   icon: <FaFacebookMessenger size={20} />,
    //   url: `fb-messenger://share?link=${encodeURIComponent(inviteLink)}&app_id=YOUR_APP_ID`,
    //   iconColor: '#0078FF', // Messenger blue
    //   bgColor: 'rgba(0, 120, 255, 0.2)'
    // }
    
  ];

  const handleShare = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Layout active={4} hideheaderbg={true}>
      <HeaderArea user={user} />
      <div className="flex flex-col gap-[12px] my-[24px]">
        <div className="border-[1px] flex items-center border-[#35333E] bg-main p-2 rounded-[12px] gap-2">
          <img src={gift} className='h-[40px]' alt="" />
          <div>
            <h1 className="text-[18px] font-semibold text-[#FFFFFF]">Share With Friends</h1>
            <div className="flex items-center gap-[2px]">
              <img src={coin} className="h-[17.2px]" alt="" />
              <p className="text-[16px] text-[#9A9BA0] font-normal">
                <span className="font-normal text-white/60">Share and grow your network</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {socialPlatforms.map((platform) => (
            <div
              key={platform.name}
              onClick={() => handleShare(platform.url)}
              className="border-[1px] flex items-center border-[#35333E] bg-main px-4 py-2 rounded-[12px] gap-2 cursor-pointer transition-all duration-300"
            >
              <div 
                className="p-2 rounded-lg"
                style={{ 
                  backgroundColor: platform.bgColor,
                  color: platform.iconColor 
                }}
              >
                {platform.icon}
              </div>
              <span className="text-[16px] text-white/90 font-normal">
                Share on {platform.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default SocialShare;