import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Layout } from '../components/Layout';
import axiosInstance from '../hooks/axiosInstance';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { useAuthStore } from '../store/useAuthStore';
import { useQueryClient } from '@tanstack/react-query';
import { parseInitData } from '@telegram-apps/sdk';

const LinkAccount = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuthStore();
    const queryClient = useQueryClient();

    let rawData;

    if (process.env.REACT_APP_ENV === 'local') {

        rawData = 'query_id=AAFGrjAuAAAAAEauMC6CvQJ3&user=%7B%22id%22%3A774942278%2C%22first_name%22%3A%22Rasel%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ProRasel%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730379811&hash=bf362efda23857d46b7cf462786a06ded20a2ef513ac4dd671fbb0af6bdeed64';
    } else {

        const initDataRaw = localStorage.getItem('initDataRaw');

        rawData = initDataRaw;


    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const rawdata = parseInitData(rawData);
        const telegramId = rawdata.user.id;
        try {
            const response = await axiosInstance.post('/user/auth/tele-sign', { userId, password, id: telegramId });
            if (response.data.token) {
                const userWithToken = { ...response.data.user, token: response.data.token };

                localStorage.setItem('authToken', rawData);
                login(userWithToken, rawData, queryClient);
                navigate("/home");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || "Failed to link account. Check user ID and password.";
            setError(errorMessage);
        }
    };

    return (
        <Layout hideheaderbg={true}>
            <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6">
                <h1 className="text-[18px] text-white/90 font-extra-bold text-center mb-4">Link Your Account</h1>
                <p className="text-[14px] font-normal text-[#9A9BA0] text-center mb-6">
                    Enter your User ID and Password to link your existing account.
                </p>

                <form onSubmit={handleSubmit} className="w-full max-w-md bg-main border border-main p-6 rounded-[12px] shadow-glow">
                    <Input
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        placeholder="User ID"
                        required
                        className="mb-4"
                    />
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                        className="mb-4"
                    />

                    {error && <p className="text-[#FF6B6B] mb-4">{error}</p>}

                    <Button type="submit" className="w-full h-12 bg-main border border-main text-white/90 font-normal rounded-full">
                        Link Account
                    </Button>
                </form>

                {/* Link to create account */}
                <p className="text-[#9A9BA0] mt-4">
                    Don’t have an account?
                    <Link to="/create-account" className="text-white/90 font-bold ml-1">
                        Create Account
                    </Link>
                </p>
            </div>
        </Layout>
    );
};

export default LinkAccount;
