import { useCallback } from 'react';
import { hapticFeedback, isHapticFeedbackSupported } from '@telegram-apps/sdk';

export const useHapticFeedback = () => {
  const triggerHaptic = useCallback(() => {
    if (isHapticFeedbackSupported()) {
      hapticFeedback.impactOccurred('medium');
    } else {
      // Fallback vibration for devices that don't support haptic
      if ('vibrate' in navigator) {
        navigator.vibrate(100);
      }
    }
  }, []);

  return {
    triggerHaptic,
    isSupported: isHapticFeedbackSupported()
  };
};