import { useMemo, useState } from "react";
import { useAuthStore } from "../store/useAuthStore";
import { useCheckBurnEligibility, useCheckRedeemEligibility, useProcessBurn, useProcessRankAction, useRankInfo } from "../api/rank";
import { hapticFeedback, isHapticFeedbackSupported } from "@telegram-apps/sdk";
import { Layout } from "../components/Layout";
import HeaderArea from "../components/home/HeaderArea";
import starYellow from "../assets/img/home/star-yellow.svg";
import RewardModal from "../components/modals/RewardModal";
import { useNotification } from "../hooks/useNotification";
import { Check } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";



export const RankAchievements = () => {
  const { user } = useAuthStore();
  const [modalState, setModalState] = useState({
    isOpen: false,
    content: '',
    actionText: '',
    actionType: '',
    rank: null,
    onConfirm: null
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    type: "info",
    message: "",
  });

  const userRank = useMemo(() => user?.rank, [user?.rank]);
  const queryClient = useQueryClient();
  // Use only one hook to fetch the rank info
  const { data: rankInfo } = useRankInfo(userRank, {
    enabled: !!userRank,
  });
  const { mutate: checkRedeem } = useCheckRedeemEligibility();
  const { mutate: checkBurn } = useCheckBurnEligibility();
  const { mutate: processBurn, status: isBurningStatus } = useProcessBurn();
  const isBurning = isBurningStatus === 'pending';
  const { showNotification } = useNotification();
  console.log("isBurning", isBurningStatus);
  const [isBurningAction, setIsBurningAction] = useState(false);
  const { mutate: processAction, status: statusProcessrank } = useProcessRankAction();
  const isProcessing = statusProcessrank === 'pending';
  console.log("statusProcessrank", statusProcessrank);

  const currentRankDetails = rankInfo;
  const handleRedeem = (rank) => {
    checkRedeem(rank, {
      onSuccess: (response) => {
        if (response.canRedeem) {
          setModalState({
            isOpen: true,
            content: response.message,
            actionText: 'Redeem Now',
            actionType: 'redeem',
            rank,
            onConfirm: (pin) => handleConfirmAction('redeem', rank, pin)
          });
        } else {
          showNotification("warning", response.message);

        }
      }
    });
  };
  const handleBurn = (rank) => {
     setIsBurningAction(true);
    checkBurn(rank, {
      onSuccess: (response) => {
        if (response.canBurn) {
          setModalState({
            isOpen: true,
            content: `
            <form class="space-y-4">
              <div>
                <label class="text-white text-sm">Coins to Deduct:</label>
                <input
                  type="text"
                  class="w-full bg-[#2A1D5C]/50 p-2 rounded text-white"
                  value="${response.burnDetails.coinsRequired.toLocaleString()} Coins"
                  readOnly
                />
              </div>
              <div>
                <label class="text-white text-sm">You Will Receive:</label>
                <input
                  type="text"
                  
                  class="w-full bg-[#2A1D5C]/50 p-2 rounded text-white"
                  value="$${response.burnDetails.profitUsd}"
                  readOnly
                />
              </div>
            </form>
          `,
            actionText: 'Burn Now',
            actionType: 'burn',
            rank,
            onConfirm: () => {
              const enteredPin = document.getElementById('transactionPinInput')?.value;
              handleBurnConfirm(rank, enteredPin);
            },
          });
        } else {
          showNotification("warning", response.message);

        }
        setIsBurningAction(false);
      },
      onError: (error) => {
         setIsBurningAction(false);
        showNotification("error", error.response?.data?.message || "Failed to check burn eligibility");
      },
    });
  };


  const handleBurnConfirm = (rank, transactionPin) => {
    processBurn(
      { rank, transactionPin },
      {
        onSuccess: (response) => {
          if (isHapticFeedbackSupported()) {
            hapticFeedback.notificationOccurred('success');
          }
          setModalState((prev) => ({ ...prev, isOpen: false }));
          showNotification("success", response.message);
          queryClient.invalidateQueries(["tapInfo"]); // Invalidate tap info query
          queryClient.invalidateQueries(["rankInfo"]); // Invalidate rank info query if relevant

        },
        onError: (error) => {
          if (isHapticFeedbackSupported()) {
            hapticFeedback.notificationOccurred('error');
          }
          showNotification("error", error.response?.data?.message || "Failed to check burn eligibility");


        },
      }
    );
  };
  const handleConfirmAction = (actionType, rank, transactionPin) => {
    processAction(
      { actionType, rank, transactionPin },
      {
        onSuccess: (response) => {
          if (isHapticFeedbackSupported()) {
            hapticFeedback.notificationOccurred('success');
          }
          setModalState((prev) => ({ ...prev, isOpen: false }));
          showNotification("success", response.message);
        },
        onError: (error) => {
          if (isHapticFeedbackSupported()) {
            hapticFeedback.notificationOccurred('error');
          }

          // Display error in notification or modal
          const errorMessage = error.response?.data?.message || "Action failed. Please try again.";
          showNotification("error", errorMessage);
          queryClient.invalidateQueries(["tapInfo"]); // Invalidate tap info query
          queryClient.invalidateQueries(["rankInfo"]); // Invalidate rank info query if relevant
          // Immediately stop loading
          setModalState((prev) => ({ ...prev, loading: false }));
        },
        onSettled: () => {
          // Ensure loading state is reset
          setModalState((prev) => ({ ...prev, loading: false }));
        },
      }
    );
  };


  const getRankStars = (rank) => {
    if (rank === "Miner") return 0;
    const match = rank.match(/\d+/);
    return match ? parseInt(match[0]) : 0;
  };
  // if (!rankInfo) return null;

  return (
    <Layout active={4} hideheaderbg={true}>
      <HeaderArea user={user} />

      {/* Current Rank Stars Display */}
      {rankInfo?.currentRank && (
        <div className="flex flex-col items-center gap-3 mt-4 mb-6">
          <div className="flex items-center gap-2">
            <p className="text-[#F4BE0F] text-sm font-medium">Current Rank:</p>
            <p className="text-white text-sm font-medium">{rankInfo.currentRank}</p>
          </div>
          <div className="flex items-center gap-2 bg-secondary p-2 rounded-full">
            {rankInfo.currentRank !== "Miner" && (


              [...Array(getRankStars(rankInfo.currentRank))].map((_, i) => (
                <img key={i} src={starYellow} alt="star" className="w-6 h-6" />
              ))
            )}
          </div>
          {rankInfo.nextRank && (
            <>
              <p className="text-gray-400 text-md leading-none ">
                Next Rank: {rankInfo.nextRank}
              </p>
              <p className="text-xs text-gray-400 -mt-1 leading-none">Requirment : {rankInfo?.nextRankRequirements?.requirementLabel}</p>
            </>

          )}
        </div>
      )}
      {/* Main Content Card */}
      <div className="flex flex-col  gap-4">
        <div className="rounded-xl p-4 border border-main">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 flex items-center justify-center bg-secondary rounded-full">
              {rankInfo?.currentRank !== "Miner" && (
                <img src={starYellow} alt="star" className="w-6 h-6" />
              )}
            </div>
            <div>
              <h2 className="text-xl font-semibold text-white">{rankInfo?.currentRank}</h2>
              <p className="text-sm text-gray-400">Required Sponsors: {currentRankDetails?.requirementLabel}</p>
            </div>
          </div>

          {currentRankDetails && (
            <div className="flex flex-col gap-2 mt-4">
              <div className="flex items-center gap-2">
                <Check size={16} className="text-white/90" />
                <span className="text-sm font-medium text-white/90">
                  {currentRankDetails.bonusCoins} Coins
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Check size={16} className="text-white/90" />
                <span className="text-sm font-medium text-white/90">
                  ${currentRankDetails.dailyBonusUsd} Daily
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Check size={16} className="text-white/90" />
                <span className="text-sm font-medium text-white/90">
                  {currentRankDetails.coinsPerTap} Coins/Tap
                </span>
              </div>

              {currentRankDetails.bonusCoins > 0 && (
                <div className="flex gap-2 mt-4">
                  {rankInfo?.rewardStatus?.status === 'completed' ? (
                    <button
                      disabled
                      className="flex-1 px-4 py-2 bg-secondary border border-main text-white/90 rounded-lg text-sm font-medium cursor-not-allowed"
                    >
                      Redeemed
                    </button>
                  ) : (
                    <button
                      onClick={() => handleRedeem(rankInfo.currentRank)}
                      className="flex-1 px-4 py-2 bg-main border border-main text-white/90 rounded-lg text-sm font-medium transition-colors"
                    >
                      Redeem
                    </button>
                  )}

                  {rankInfo.nextRank && (
                    <button
                      onClick={() => handleBurn(rankInfo.currentRank)}
                     disabled={isBurning || isBurningAction} 
                      className={`flex-1 px-4 py-2 ${isBurning
                        ? 'bg-gray-600 cursor-not-allowed'
                        : 'bg-main border border-main'
                        } text-white rounded-lg text-sm font-medium`}
                    >
                      {isBurning || isBurningAction ? 'Processing...' : 'Burn'}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <RewardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
        content={modalState.content}
        actionText={modalState.actionText}
        onConfirm={modalState.onConfirm}
        loading={isProcessing || isBurning}
      />
    </Layout>
  );
};