import { Routes, Route, Navigate } from "react-router-dom";
import Home from './Home';

import LinkAccount from "./pages/LinkAccount";
import CreateAccount from "./pages/CreateAccount";
import { Loading } from "./pages/Loading";
import { useAuthStore } from "./store/useAuthStore";
import { Referral } from "./pages/Referral";
import Rewards from "./pages/Rewards";
import Swap from "./pages/Swap";
import { init,expandViewport } from "@telegram-apps/sdk";
import { RankAchievements } from "./pages/RankAchievements";
import { TapGame } from "./TapsGame";
import { WebApp } from "./WebApp";
import SocialShare from "./pages/SocialShare";
import { Announcement } from "./pages/Announcement";
import CredentialUpdatePage from "./pages/CredentialUpdate";

const App = () => {
   
  const { authStatus, initializing, loading } = useAuthStore();
  // Show loading spinner if initializing or loading
  if (initializing || loading) {
    return <Loading />;
  }
  console.log("Auth Status:", authStatus);

  return (
    <Routes>
      {authStatus === "incomplete" ? (
        // Routes when authentication is incomplete
        <>
          <Route path="/link-account" element={<LinkAccount />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="*" element={<Navigate to="/link-account" replace />} />
        </>
      ) : (
        // Routes when authentication is complete
        <>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<TapGame />} />
          <Route path="/tap" element={<TapGame />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/share" element={<SocialShare />} />
          <Route path="/swap" element={<Swap />} />
        <Route path="/rank-achievement" element={<RankAchievements />} />
        <Route path="/announcement" element={<Announcement />} />
        <Route path="/update-credentials" element={<CredentialUpdatePage />} />
      
      
          <Route path="/referral" element={<Referral />} />
            <Route path="/web-app" element={<WebApp />} />
          
        
         
          <Route path="*" element={<Navigate to="/home" replace />} />
        </>
      )}
    </Routes>
  );
};

export default App;
