import React from 'react';
import { Layout } from '../components/Layout';
import HeaderArea from '../components/home/HeaderArea';
import { useAuthStore } from '../store/useAuthStore';
import { AlertCircle } from 'lucide-react';

export const Announcement = () => {
  const { user } = useAuthStore();

  // Static announcement content
  const announcement = {
    title: "Important Notice",
    details: [
      "<strong>AirDrop is going to be launched again to all Stars on 23/12/2024. To avail this AirDrop, you have to meet the following conditions:</strong>",
      "1.⁠ ⁠Those who have already received AirDrop as a star must have a minimum of four new Stars from direct referrals and 7 lakh coins remaining in their account.",
      "2.⁠ ⁠Those who are going to receive AirDrop for the first time as a star must have a minimum of 2 lakh coins remaining in their account.",
      "3.⁠ ⁠Your coins will be burned based on your performance.",
      "<div class='border p-1 border-secondary'><span class='font-bolder text-yellow-300'>N: B:</span> Users who fail to swap daily cents will be considered ineligible, and those achieving their designation will not be counted for consideration.</div>"
    ],
  };

  return (
    <Layout active={5} hideheaderbg={true}>
      <HeaderArea user={user} />

      <div className="w-full rounded-[23px] px-2 my-2">
        <div className="rounded-xl bg-main border border-main p-4 shadow-lg">
          {/* Title Section */}
          <div className="flex items-center gap-4 mb-8">
            <div className="flex items-center justify-center bg-main rounded-full p-3">
              <AlertCircle size={28} color="#F4BE0F" />
            </div>
            <h1 className="text-2xl font-bold text-white/90">{announcement.title}</h1>
          </div>

          {/* Content Section */}
          <div className="text-white text-base font-medium leading-7 space-y-6">
            {announcement.details.map((paragraph, index) => (
              <p
                key={index}
                dangerouslySetInnerHTML={{ __html: paragraph }} // Render HTML for N: B:
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
