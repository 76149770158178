import { memo } from "react";
import coinImage from "../../assets/img/CatsCoin.png";

export const CoinBalance = memo(({ balance }) => {
  const displayBalance = Math.max(0, balance).toLocaleString();
  
  return (
    <div className="flex items-center justify-center gap-[6px]">
      <img src={coinImage} className="h-[35px]" alt="Coin" />
      <h1 className="text-white font-extra-bold text-[32px]">
        {displayBalance}
      </h1>
    </div>
  );
});

CoinBalance.displayName = 'CoinBalance';