import React, { useState } from 'react';
import { Layout } from '../components/Layout';
import giftIcon from '../assets/img/home/star-yellow.svg';
import badgeIcon from '../assets/img/badge.svg';
import HeaderArea from '../components/home/HeaderArea';
import { useAuthStore } from '../store/useAuthStore';
import { CheckCheck, CheckCircle2Icon } from 'lucide-react';

const Rewards = () => {
  const [activeTab, setActiveTab] = useState('Ranks');
  const { user } = useAuthStore();

  const rankData = [
    { name: 'Star 1', condition: 'Need 15 Sponsor', bonus: ['0.1M Coin', '$0.10 Daily + $0.01/STAR', '20 Coins/Tap'] },
    { name: 'Star 2', condition: "Need 9 'STAR 1' From Referral", bonus: ['0.3M Coin','$0.40 Daily', '40 Coins/Tap'] },
    { name: 'Star 3', condition: "Need 7 'STAR 2' From Referral", bonus: ['1M Coin','$0.90 Daily', '60 Coins/Tap'] },
    { name: 'Star 4', condition: "Need 5 'STAR 3' From Referral", bonus: ['6M Coin','$2 Daily', '80 Coins/Tap'] },
    { name: 'Star 5', condition: "Need 3 'STAR 4' From Referral", bonus: ['20M Coin','$5 Daily', '100 Coins/Tap'] },
  ];

  const levelData = [
    { name: 'Level 1', condition: 'NO Extra Condition', bonus: ['1000 Coins/Referral'] },
    { name: 'Level 2', condition: 'Achieve 1 Star Designation', bonus: ['500 Coins/Team'] },
    { name: 'Level 3', condition: 'Achieve 2 Star Designation', bonus: ['250 Coins/Team'] },
    { name: 'Level 4', condition: 'Achieve 3 Star Designation', bonus: ['125 Coins/Team'] },
    { name: 'Level 5', condition: 'Achieve 4 Star Designation', bonus: ['55 Coins/Team'] },
    { name: 'Level 6', condition: 'Achieve 5 Star Designation', bonus: ['25 Coins/Team'] },
  ];

  const renderData = activeTab === 'Ranks' ? rankData : levelData;

  return (
    <Layout active={6} hideheaderbg={true}>
      <HeaderArea user={user} />

      {/* Redesigned Tab buttons */}
      <div className="flex justify-center mt-2 mb-6 p-1 bg-[#1A1A1A] rounded-xl max-w-[280px] mx-auto">
        <button
          onClick={() => setActiveTab('Ranks')}
          className={`flex-1 px-6 py-2.5 font-medium text-sm rounded-lg  ${
            activeTab === 'Ranks' 
            ? 'border border-main text-white/90 shadow-glow' 
            : 'text-white hover:bg-white/5'
          }`}
        >
          Ranks
        </button>
        <button
          onClick={() => setActiveTab('Levels')}
          className={`flex-1 px-6 py-2.5 font-medium text-sm rounded-lg  ${
            activeTab === 'Levels' 
            ? 'border border-main text-white/90 shadow-glow' 
            : 'text-white '
          }`}
        >
          Levels
        </button>
      </div>

      {/* Rewards List */}
      <div className="w-full rounded-[23px] px-2">
        <h1 className="text-md font-bold text-white text-center mb-4">
          Reward Distribution Plan
        </h1>

        <div className="flex flex-col gap-2">
          {renderData.map((item, index) => (
            <div 
              key={index} 
              className="flex items-start px-4 py-2 rounded-xl bg-main border border-main"
            >
             
              <div className="flex-grow">
                 <div className=" flex  gap-2 bg-main rounded-full mr-4">
                <img 
                  src={activeTab === 'Ranks' ? giftIcon : badgeIcon} 
                  alt="Icon" 
                  className="w-6 h-6" 
                />
                 <h2 className="text-xl font-semibold text-white">{item.name}</h2>
              </div>
               
                <p className="text-sm text-gray-400 mt-1">{item.condition}</p>
                <div className="flex flex-col gap-1 mt-3">
                  {item.bonus.map((bonus, idx) => (
                    <div 
                      key={idx} 
                      className="flex items-center gap-2"
                    >
                      <CheckCircle2Icon size={16} color="#ddd" />
                      <span className="text-sm font-medium text-white/90">
                        {bonus}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Rewards;