// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App"; // Import App with routes inside it
import AuthWrapper from "./hooks/AuthWrapper";
import { disableVerticalSwipes, enableClosingConfirmation, miniApp, expandViewport, init, miniAppHeaderColor, themeParams, backButton, swipeBehavior, closingBehavior, themeParamsHeaderBackgroundColor, setMiniAppHeaderColor } from "@telegram-apps/sdk";
import { NotificationProvider, useNotification } from "./hooks/useNotification";
import NotificationModal from "./components/Notification";
import { GameProvider } from "./context/GamContext";

const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV !== "local") {
  init();
  miniApp.mount();
  backButton.mount();
  themeParams.mount();
  swipeBehavior.mount();
  closingBehavior.mount();
  expandViewport(); 
   disableVerticalSwipes();
   enableClosingConfirmation();
   miniAppHeaderColor("#0D0D0D");
   themeParamsHeaderBackgroundColor("#0D0D0D");
   setMiniAppHeaderColor("#0D0D0D");
 
}
const NotificationWrapper = () => {
  const { notification, closeNotification } = useNotification();

  return (
    <NotificationModal
      isOpen={notification.isOpen}
      type={notification.type}
      message={notification.message}
      onClose={closeNotification}
    />
  );
};
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
          <NotificationProvider>

      <BrowserRouter>
        <AuthWrapper>
           <GameProvider>
          <App /> {/* App should contain the routes now */}
          </GameProvider>
        </AuthWrapper>
         <NotificationWrapper />
      </BrowserRouter>
    </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
