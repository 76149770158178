import React from 'react';
import { Home, Repeat, Users, Award, Megaphone, Share, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { GrAnnounce } from 'react-icons/gr';
import { TfiAnnouncement } from 'react-icons/tfi';
import { GiBullHorns } from 'react-icons/gi';
import { FaBullhorn, FaExchangeAlt, FaInfoCircle, FaRocket, FaShareAltSquare, FaUsers } from 'react-icons/fa';

export const Menu = ({ active }) => {
  return (
            <div className=" left-0 right-0 bottom-[10px] fixed px-2">

    <nav className='bg-main shadow-glow z-50 h-[60px] rounded-[12px]   w-full   border-[1px] border-[#504C5C] flex items-center px-[12px]'>
      {/* Home Link */}
      <Link
        to="/home"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 1 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaRocket color={active === 1 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 1 && '!text-[#fff]'}`}>Home</p> */}
      </Link>

      {/* Swap Link */}
      <Link
        to="/swap"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 2 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaExchangeAlt color={active === 2 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 2 && '!text-[#fff]'}`}>Swap</p> */}
      </Link>

      {/* Referral Link */}
      <Link
        to="/referral"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 3 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaUsers color={active === 3 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 3 && '!text-[#fff]'}`}>Referral</p> */}
      </Link>
      <Link
        to="/share"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 4 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaShareAltSquare color={active === 4 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 4 && '!text-[#fff]'}`}>Notice</p> */}
      </Link>
      {/* Rewards Link */}
      <Link
        to="/announcement"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 5 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaBullhorn color={active === 5 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 4 && '!text-[#fff]'}`}>Notice</p> */}
      </Link>
      {/* Rewards Link */}
      <Link
        to="/rewards"
        className={`flex flex-col items-center justify-center flex-grow py-1 transition-all duration-200
          ${active === 6 ? "bg-secondary border-[1px] border-[#4A4657] rounded-[6px]" : ""}`}
      >
        <FaInfoCircle color={active === 6 ? '#fff' : '#9A9BA0'} size={24} />
        {/* <p className={`text-[16px] text-[#fbd002] ${active === 4 && '!text-[#fff]'}`}>Rewards</p> */}
      </Link>
    </nav>
    </div>
  );
};

export default Menu;