// api/rank.js
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../hooks/axiosInstance';

// Fetch rank info
export const useRankInfo = (userRank) => {
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['rankInfo', userRank],
        queryFn: async () => {
            const { data } = await axiosInstance.get('/user/rank/info');
            return data;
        },
        staleTime: 1000 * 60 * 2,
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!userRank,
        keepPreviousData: true,
        initialData: () => {
            return queryClient.getQueryData(['rankInfo', userRank]);
        }
    });
};


// Add proper error handling and retries
export const useProcessRankAction = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ actionType, rank, transactionPin }) => {
            const { data } = await axiosInstance.post('/user/rank/process', {
                actionType,
                rank,
                transactionPin
            });
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rankInfo']);
        },
        retry: (failureCount, error) => {
            // Disable retries for client-side errors (e.g., invalid PIN)
            const status = error.response?.status;
            if (status === 400 || status === 401) return false; // Client-side error
            if (status === 429) return false; // Rate-limited
            return failureCount < 3; // Retry for other server-side errors
        }
    });
};

// Check redeem eligibility
export const useCheckRedeemEligibility = () => {
    return useMutation({
        mutationFn: async (rank) => {
            const { data } = await axiosInstance.get(`/user/rank/check-redeem/${rank}`);
            return data;
        }
    });
};

// Check burn eligibility
export const useCheckBurnEligibility = () => {
    return useMutation({
        mutationFn: async (rank) => {
            const { data } = await axiosInstance.get(`/user/rank/check-burn/${rank}`);
            return data;
        }
    });
};

// Hook to get rank details based on current rank
export const useCurrentRankDetails = (currentRank) => {
    return useQuery({
        queryKey: ['rankDetails', currentRank],
        queryFn: async () => {
            if (!currentRank) return null;
            const { data } = await axiosInstance.get('/user/rank/info');
            return data;
        },
        enabled: !!currentRank,
        staleTime: 1000 * 60 * 5 // 5 minutes
    });
};
export const useProcessBurn = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ rank, transactionPin }) => {
            const { data } = await axiosInstance.post('/user/rank/process-burn', {
                rank,
                transactionPin
            });
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rankInfo']);
            queryClient.invalidateQueries(['user']);
            queryClient.invalidateQueries(['coinBalance']);
        },
        retry: (failureCount, error) => {
            const status = error.response?.status;
            if (status === 429 || status === 400 || status === 401) return false; // No retry for these errors
            return failureCount < 3; // Retry up to 3 times for other errors
        },
    });
};