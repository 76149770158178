import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import flyingCat from "../assets/img/tap1.png";

export const Loading = () => {
  const [dots, setDots] = useState(0);

  // Simulate dot animation
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev < 3 ? prev + 1 : 0)); // Cycle through 0, 1, 2, 3 dots
    }, 500); // Update every 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout active={1} nomenu={true}>
      <div className="relative flex flex-col justify-center items-center min-h-screen overflow-hidden fullscreen-layout">
        <span className="w-[348px] h-[348px] bg-main block rounded-full blur-[250px] absolute left-1/2 -translate-x-1/2 top-[400px]"></span>

        {/* Flying Cat Image */}
        <img src={flyingCat} alt="Flying Cat" className="mx-auto w-[250px] my-10 relative z-10" />

        {/* Modern Loader Below the Image */}
        <div className="relative flex justify-center items-center space-x-2 mt-6">
          <div className="w-3 h-3 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
          <div className="w-3 h-3 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '0.3s' }}></div>
          <div className="w-3 h-3 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '0.5s' }}></div>
        </div>

        {/* Loading Text with Fixed Space for Dots */}
        <p className="mt-4 text-lg font-normal text-gray-300 flex items-center">
          Loading
          <span className="inline-block w-6 text-left">
            {Array.from({ length: dots }).map((_, index) => (
              <span key={index}>.</span>
            ))}
          </span>
        </p>
      </div>
    </Layout>
  );
};
