import React, { memo, useEffect, useState } from 'react';
import { X } from 'lucide-react';

export const ErrorDisplay = memo(({ gameState, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(100); // Start with full progress (100%)
  console.log('message', gameState.error);

  // Automatically show and hide the error based on the message
  useEffect(() => {
    if (gameState.error) {
      setVisible(true); // Show the error
      setProgress(100); // Reset progress to full

      const duration = 10000; // 10 seconds
      const intervalTime = 100; // Update every 100ms
      const decrement = 100 / (duration / intervalTime); // Calculate decrement per interval

      const timer = setTimeout(() => {
        setVisible(false); // Hide the error after 10 seconds
        if (onClose) onClose(); // Call the onClose callback if provided
      }, duration);

      const progressInterval = setInterval(() => {
        setProgress((prev) => Math.max(prev - decrement, 0)); // Update progress
      }, intervalTime);

      return () => {
        clearTimeout(timer); // Cleanup timer
        clearInterval(progressInterval); // Cleanup progress interval
      };
    }
  }, [gameState, onClose]); // Run this effect whenever the message changes

  // Reset visibility when the message changes
  useEffect(() => {
    if (gameState.error) {
      setVisible(true); // Ensure visibility is reset for a new error
    }
  }, [gameState]);

  // Manually close the error
  const handleClose = () => {
    setVisible(false); // Hide the error immediately
    if (onClose) onClose(); // Call the onClose callback
  };

  if (!visible || !gameState.error) return null;

  return (
    <div className="mb-2 relative px-4 py-2 bg-main border border-main rounded-lg relative flex flex-col">
        <div
        className="h-[2px] absolute top-0 left-0 bg-red-500/10 rounded-full overflow-hidden"
        style={{ width: '100%' }}
      >
        <div
          className="h-full bg-white/50 transition-all duration-[100ms]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="flex items-center">
        <p className="text-white/80 text-xs flex-grow">{gameState.error}</p>
        <button
          className="p-2 bg-white/50 hover:bg-white-500/30 rounded-full ml-2"
          onClick={handleClose}
          aria-label="Close"
        >
          <X className="text-black w-4 h-4" />
        </button>
      </div>
      {/* Progress Bar */}
    
    </div>
  );
});

ErrorDisplay.displayName = 'ErrorDisplay';
