import React from 'react';
import TopBox from './common/TopBox';
import gameIcon from "../assets/img/common/game.png";
import airdropImage from "../assets/img/common/gift-box.png";
import ytIcon from "../assets/img/common/yt.png";

export const TopBoxSection = ({ gameState }) => {
  return (
//<div className="p-4 rounded-lg bg-[#1E1E2C] shadow-lg border border-[#C121E5]">
      <div className="grid grid-cols-3 gap-[12px] mt-1">
      {/* <TopBox isActive={false}>
        <img src={coinImage} className="h-6" loading="lazy" alt="Reward Icon" />
        <h1 className="text-[18px] dailyBonusBtnTitle font-bold text-[#D2D3D9] mt-1">{gameState.coinBalance}</h1>
       
      </TopBox> */}
      
      <TopBox isActive={false}>
        <img src={gameIcon} className="h-6" loading="lazy" alt="Reward Icon" />
        <p className="font-medium text-[12px] gap-2 text-white w-[60px] h-[20px] rounded-[8px] bg-secondary mt-2 flex items-center justify-center">
          Games
        </p>
      </TopBox>
      <TopBox isActive={false}>
        <img src={airdropImage} className="h-6" loading="lazy" alt="Reward Icon" />
        <p className="font-medium text-[12px] gap-2 text-white w-[60px] h-[20px] rounded-[8px] bg-secondary mt-2 flex items-center justify-center">
          Airdrop
        </p>
      </TopBox>

      <TopBox isActive={false}>
        <img src={ytIcon} className="h-6" loading="lazy" alt="Reward Icon" />
        <p className="font-medium text-[12px] gap-2 text-white w-[60px] h-[20px] rounded-[8px] bg-secondary mt-2 flex items-center justify-center">
          Youtube
        </p>
      </TopBox>
    </div>
   // </div>
  );
};

export default TopBoxSection;
