import { useState } from "react";

const ReferralCodeModal = ({ isOpen, onSubmit, loading }) => {
  const [referralCode, setReferralCode] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!referralCode.trim()) {
      setError("Referral code is required.");
      return;
    }

    if (loading) return;

    setError("");
    onSubmit(referralCode);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-main border-main shadow-glow rounded-xl p-6 w-full max-w-md border border-[#DAA520]/10">
        <h3 className="text-xl font-bold text-white mb-4 text-center">
          Set Your Referral Code
        </h3>
        <p className="text-gray-300 mb-6 text-center">
          Please set your referral code to proceed.
        </p>
        <input
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          placeholder="Enter Referral Code"
          className="w-full px-4 py-2 mb-4 text-gray-900 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DAA520]"
        />
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="flex justify-center mt-6">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className={`bg-main border border-main px-6 py-1.5 rounded-full text-white/90 cursor-pointer min-w-[120px] ${
              loading ? "cursor-not-allowed" : ""
            } transition-colors`}
          >
            {loading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white/90"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
               
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralCodeModal;
