import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Loading } from "../pages/Loading";
import { useAuthStore } from "../store/useAuthStore";

const AuthWrapper = ({ children }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, authStatus, fetchUserData, registerUser, initializing } = useAuthStore();


    useEffect(() => {
        const handleNavigation = () => {
            if (authStatus === "incomplete" && location.pathname !== "/link-account" && location.pathname !== "/create-account") {
                navigate("/create-account", { replace: true });
            } else if (authStatus === "authenticated" && location.pathname !== "/home") {
                navigate("/home", { replace: true });
            }
        };

        if (!initializing && !loading) {
            handleNavigation();
        }
    }, [authStatus, initializing, loading]);

    useEffect(() => {
        const authToken = localStorage.getItem("authToken");

        console.log("Auth Token on load:", authToken);
        console.log("Initializing:", initializing);
        console.log("Auth Status:", authStatus);
        console.log("Loading:", loading);

        if (initializing) {
             if (authStatus === "unauthenticated") {
                console.log("Registering user due to missing auth token...");
                registerUser(queryClient);
            }
            // if (authToken && authStatus === "unauthenticated") {
            //     console.log("Fetching user data due to existing auth token...");
            //     fetchUserData(queryClient);
            // } else if (!authToken && authStatus === "unauthenticated") {
            //     console.log("Registering user due to missing auth token...");
            //     registerUser(queryClient);
            // }
        }
    }, [authStatus, fetchUserData, registerUser, initializing, queryClient]);


    console.log('loading', loading);

    if (loading || initializing) {
        return <Loading />;
    }

    return <>{children}</>;
};

export default AuthWrapper;
